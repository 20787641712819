import React, {Component} from 'react';
import BaseIcon from 'assets/svgIcon/baseIcon'
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import newsInformUrl from 'assets/images/newsInform.png';
import {fetchData} from 'helpers';

class ServiceFunc extends Component {
  state = {
    newsNumber: 0,
  }

  toRoute(history, pathName) {
    if (pathName === 'complaint') {
      return;
    }
    if (pathName === 'hotline') {
      history.push(`/`);
      this.props.dispatch({
        type: 'SET_SUBMIT_COVER',
        submitCover: true
      })
      return;
    }
    history.push('/' + pathName)
  }

  componentDidMount() {
    if (!this.props.isLogin) return;
    if (this.props.showNews && this.props.history.location.pathname !== '/myquiz/quizdetail') {
      this.getNewMessagesNumber();
    }
  }

  getNewMessagesNumber() {
    fetchData('getNewMessagesNumber').then((result) => {
      if (result) {
        this.props.dispatch({
          type: 'CHANGE_SHOW_NEWS',
          isShowNews: result.data.total,
        })
      }
    }).catch((err) => {

    });
  }

  render() {
    const {iconName, title, pathName, location, showNews} = this.props;
    // const search = new URLSearchParams(location.search).get('type');
    return (
      <section>
        <style jsx>{`
                   .box {
                       height: 100px;
                       border-bottom: 1px solid #E9E9E9;
                       padding: 20px 13px 20px 20px;
                       display: flex;
                       align-items: center;
                       background: ${location.pathname === `/${pathName}` ? 'linear-gradient(#f9f9f9, #f0f0f0)' : 'rgba(255,255,255,1)'};
                       box-sizing: border-box;
                       position: relative;
                   }
                   .title {
                       text-align: right;
                       font-weight: bold;
                       font-size:16px;
                       margin-bottom: 5px;
                   }
                   .service-details {
                       width: 100%;
                    //    padding-right: 10px;
                   }
                   .service-details .detail{
                      font-size: 12px;
                      color: #999;
                      text-align: right;
                      white-space: nowrap;
                   }
                   .news-inform {
                       position: absolute;
                       right: -6px;
                       top: 0;
                       width: 63px;
                       height: 30px;
                   }
                   .news-inform img {
                       width: 100%;
                       height: 100%;
                   }
                   .news-inform .font-new {
                       position: absolute;
                    //    width: 44px;
                    //    height: 14px;
                       right: 50%;
                       top: 1px;
                       white-space: nowrap;
                       transform: translateX(50%);
                       font-size: 12px;
                       color: white;
                       font-weight: bold;
                   }
               `}
        </style>

        <section className={`box ${pathName === 'hotline' ? '' : 'add-pointer'}`}
                 onClick={() => this.toRoute(this.props.history, pathName)}>
          <div className="svg-logo"><BaseIcon iconName={iconName} width={60} height={60}/></div>
          <ul className="service-details">
            <li className="title">{title.title1}</li>
            {Array.isArray(title.title2) ? title.title2.map((item, index) => {
                return <li key={index} className="detail">{item}</li>
              }) :
              <li className="detail">{title.title2}</li>}
            <li className="detail">{title.title3}</li>
          </ul>
          {showNews && Boolean(this.props.isShowNews) &&
          (<div className="news-inform">
            <img src={newsInformUrl} alt=""/>
            <div className="font-new">新消息</div>
          </div>)}
        </section>
      </section>
    )
  }
}

function mapStateToProps(state) {
  return {
    isShowNews: state.showReducer.isShowNews,
    isLogin: state.showReducer.isLogin,
  }
}

export default connect(mapStateToProps)(withRouter(ServiceFunc))