import React, { useState, useEffect, Fragment} from 'react';
import { fetchData } from 'helpers';
import { Icon } from 'antd';

const toMore = (history, id) => {
  window.sessionStorage.setItem('accountId', id);
  history.push(`/accountproblems?accountid=${id}`)
}

const toQuestionDetail = (history, fkey,id, accountId, title) => {
  window.sessionStorage.setItem('accountId', accountId);
  if (fkey) {
      // history.push(`/accountproblems/accountdetail?fkey=${fkey}&quizTitle=${title}`)
      history.push(`/onlineconsultation?fkey=${fkey}`)
  } else {
      history.push(`/accountproblems/accountcontent?fid=${id}`);
  }
}

const SingleConsult = (props) => {
    const { title, questionList, color1, color2,id,history } = props;
    return (
      <Fragment>
        <style jsx>{`
            .box {
              width: 230px;
              height: 298px;
              box-sizing: border-box;
              padding: 7px;
              background: white;
              box-shadow:0px 0px 3px 0px rgba(0,0,0,0.14);
              position: relative;
              border-radius:0px 0px 8px 0px;
              overflow: hidden;
              margin-right: 12px;
              margin-top: 10px;
              z-index: 50;
            }
            .box:nth-child(4n+0) {
              margin-right: 0;
            }
            .type-show {
              height: 94px;
              background: linear-gradient(${color1}, ${color2});
              font-size: 24px;
              color: white;
              font-weight: bold;
              text-align: center;
              line-height: 94px;
            }
            .quiz-list {
              font-size: 14px;
              margin-top: 10px;
            }
            .quiz {
              position: relative;
              padding-left: 15px;
              margin-bottom: 8px;
            }
            .circle {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: #d9d9d9;
              position: absolute;
              top: 50%;
              left: 5px;
              transform: translateY(-50%);
            }
            .more {
              width: 82px;
              height:40px;
              background: #eee;
              box-shadow: 1px 1px 4px 0px #aaa inset;
              // font-size: 12px;
              color: #999;
              line-height: 40px;
              text-align: center;
              position: absolute;
              right: -10px;
              bottom: 0;
              transform: skew(-20deg);
            }
            .more span {
              display: inline-block;
              transform: skew(20deg);
              // font-size: 12px;
            }
        `}
        </style>
        <div className="box">
          <h1 className="type-show">
            {title}
          </h1>
          <ul className="quiz-list">
            {
              questionList.map((item, index) => {
                return ( <li className="quiz add-pointer" key={index} onClick={() => toQuestionDetail(history,item.fkey,item.id, id, item.title)}><p className="circle"></p><p className="quiz-content">{item.title}</p></li>)
              })
            }
          </ul>
          <div className="more add-pointer" onClick={() => toMore(history,id)}>
            <span>更多 <Icon type="right" /></span>
          </div>
        </div>
      </Fragment>
    )
}


const AllKindsConsult = (props) => {
    const [questionList, setQuestionList] = useState([]);
    useEffect(() => {
      fetchData('getQuestionCateList').then((res) => {
        setQuestionList(res.data);
        let breads = res.data.map(item => {
          return {
            id: item.id,
            title: item.title
          }
        })
        window.sessionStorage.setItem('questionBreads', JSON.stringify(breads))
      }).catch((err) => {
        
      });
    }, [])
    return (
      <section>
         <style jsx>{`
             .box {
               display: flex;
               flex-wrap: wrap;
              //  justify-content: space-between;
             }
         `}
         </style>
         <section className="box">
           {
             questionList.map((item,index) => {
               return (
                 <SingleConsult 
                    key={index}
                    title={item.title}
                    id={item.id}
                    history={props.history} 
                    questionList={item.question_list} 
                    color1={index%2 === 0 ? '#F76666' : '#F7AA66'}
                    color2={index%2 === 0 ? '#D34040' : '#D38040'}
                  />
               )
             })
           }
         </section>
      </section>
    )
}

// function mapStateToProps(state) {
//   return {
//     questionBreads: state.questionReducer.questionBreads,
//   }
// }

export default AllKindsConsult