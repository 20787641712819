import React, { useState } from 'react';
// import Logo from '../assets/svgIcon/360logo';
import imgUrl from '../assets/images/centerBg.png';
import Logo from '../assets/images/gameLogo.png';
import { withRouter } from 'react-router';


function Header(props) {
    // const sections = ['首页','个人中心','页游','手游'];
    const sections = ['首页','个人中心'];
    const mapTo = new Map([
        [1, '//i.360.cn'],
        [2, 'http://wan.360.cn'],
        // [3, 'http://youxi.com'],
        [3, 'http://u.360.cn'],
        // [5, '/']
    ])
    const [ count ] = useState(0);
    const goHeaderRoute = (index, history) => {
        // setCount(index);
        // if (index === 5) return;
        if (index === 0 ) {
            props.history.push('/');
            return;
        }
        const url = mapTo.get(index);
        window.open(url);
    }
    const toHome = () => {
        props.history.push('/')
    }
    return (
        <section>
            <style jsx>{`
                .header-container {
                    height: 114px;
                    display:flex;
                    align-items:center;
                    position: relative;
                    min-width: 1200px;
                }
                .header-center {
                    position: relative;
                    min-width: 1200px;
                    height: 100%;
                    margin:0 auto;
                }
                .logo {
                    // margin-left: 150px;
                    position: absolute;
                    width: 314px;
                    left: -8px;
                    top: 50%;
                    transform: translateY(-50%);
                    line-height: 0;
                }
                .logo-right {
                    font-size: 22px;
                    // font-weight: 600;
                    color: white;
                    // height:32px;
                    // width: 114px;
                    position: absolute;
                    left: 170px;
                    white-space: nowrap;
                    top: 49%;
                    transform: translateY(-50%);
                }
                .sections {
                    display: flex;
                    align-items: center;
                    // margin-left: 394px;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    right: -16px;
                    // right: 360px;
                }
                .sections .section {
                    font-size: 16px;
                    margin-right: 18px;
                    cursor: pointer;
                    font-weight: bold;
                    color: white;
                }
                .sections .section-active {
                    font-size: 16px;
                    margin-right: 18px;
                    cursor: pointer;
                    color: white;
                    font-weight: bold;
                    text-shadow: 0 0 5px #fff;
                }
                .red {
                    color: #D0021B;
                }
            `}</style>
            <div className="header-container" style={{backgroundImage:'url('+imgUrl+')',backgroundSize: '100% 100%'}}>
                <section className="header-center">
                    <div className="logo add-pointer" onClick={toHome}>
                        <img className='logo' src={Logo}></img>
                        {/* <Logo width={300} height={45} fill="white"/> */}
                        {/* <span className="logo-right">客服中心</span> */}
                    </div>
                    <ul className="sections">
                        {sections.map((item,index) => {
                            return (
                            <li 
                                className={count === index ? 'section-active' : 'section'}
                                key={item} 
                                onClick={() => goHeaderRoute(index, props.history)} 
                                // style={{fontWeight: count === index ? 'bold' : 'normal'}}
                            >
                                {item}
                            </li>)
                        })}
                    </ul>
                </section>
                
            </div>
        </section>
        
    )
}

export default withRouter(Header)