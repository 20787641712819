import React from 'react'
import ServiceFunc from './serviceFunc';

const setDetails = (title1, title2, title3) => {
  return {
    title1,
    title2,
    title3,
  }
}
const title1 = setDetails('我的问题','查看历史咨询记录及','问题解决进度');
const title2 = setDetails('自助服务','热门问题','全解答');
const title3 = setDetails('提交问题','定位问题更精准','解决问题更快速');
const title4 = setDetails('贵宾服务','贵宾用户','咨询专用');
let title5 = setDetails('客服热线','010-86394715','服务时间：7*24小时');

// if(new Date().getTime() > 1601222400000){
title5.title2 = ['坦克世界：0536-3565177 ','战舰世界：0536-3565277'];
// }


const LeftContent = (props) => {
    // const title5 = setDetails('投诉通道','提升我们的服务质量','');
    return (
      <section>
         <style jsx>{`
             .left-content {
                width: 232px;
                box-shadow: 0 0 5px 3px #e5e5e5;
                margin-right: 10px;
                z-index: -1;
             }
         `}
         </style>
         <section className="left-content">
             <ServiceFunc iconName="Myquiz" title={title1} pathName='myquiz' showNews />
             <ServiceFunc iconName="Faq" title={title2} pathName='faq'/>
             <ServiceFunc iconName="Hotline" title={title3} pathName='hotline'/>
             {/* <ServiceFunc iconName="Vip" title={title4} pathName='vip'/> */}
             <ServiceFunc iconName="Complaint" title={title5} pathName='complaint'/>
         </section>
      </section>
    )
}
                          
export default LeftContent