import React, { useState, useEffect} from 'react';
import { Input,Upload, message, Button, Pagination } from 'antd';
import { connect } from 'react-redux';
import Zmage from 'react-zmage'
import { fetchData } from 'helpers';
import avatarPlayer from 'assets/images/avatarPlayer.png';
import avatarServant from 'assets/images/avatarServant.png';
import QuizInfo from './quizInfo';


const { TextArea } = Input;

const Reply = (props) => {
   const { record, ctime,index, sender, extend, length, source} = props;
   const style = {
      borderTop: '1px solid #d9d9d9',
      padding: '18px 12px',
      background: index === length-1 ? '#FFF2F2' : '#fff',
   }
   const imgSets = extend.map(item => {
      return {
          src: item.content,
          className: 'zimageMessage'
      }
  });
   return (
      <section style={style}>
         <style>
            {`
            .zimage-message img {
               width: 80px;
               height: 80px;
               margin-left: 40px;
               margin-top: 6px;
            }
            `}
         </style>
         <style jsx>{`
            .personal {
               display: flex;
               align-items: center;
               justify-content: space-between;
            }
            .personal-info {
               display: flex;
               align-items: center;
            }
            .avatar {
               height: 30px;
               width: 30px;
               border-radius: 50%;
               background: skyblue;
            }
            .avatar img {
               width: 100%;
               height: 100%;
            }
            .email {
               color: #999;
               margin-left: 10px;
            }
            .date {
               color: #999;
            }
            .reply-content {
               margin-left: 40px;
               line-height: 22px;
               margin-top: 6px;
               font-weight: ${index === length-1 ? '600' : 'normal'}
            }
         `}
         </style>
         <section className="personal">
            <div className="personal-info">
               <div className="avatar">
                  <img alt="" src={Number(source) === 2 ? avatarServant : avatarPlayer}/>
               </div>
               <p className="email">{sender}</p>
            </div>
            <p className="date">{ctime}</p>
         </section>
         <div className="reply-content" dangerouslySetInnerHTML={{__html: record}}>
         </div>
         <div className="zimage-message">
            {extend.map((item,index) => {
               return (<Zmage 
                  src={item.content}
                  key={index}
                  set={imgSets} 
                  // onBrowsing={onBrowsing}
                  // backdrop="rgba(0, 0, 0,0.15)" 
                  controller={{
                      download: false,
                      zoom: false,
                  }}
                  edge={200}
                  defaultPage={index}
              alt=""/>)
            })}
         </div>
      </section>
   )
}

let qucParams = ['qid', 'timestamp', 'src', 'ukey', 'fkey'];

const QuizDetail = (props) => {
   const style = {
      width: '100%',
      // fontSize: '12px',
   }
   const { dispatch } = props;
   const [ loading, setLoading ] = useState(false);
   const [ inpVal, setInpVal ] = useState('');
   const [ fileList, setFileList ] = useState([]);
   const [ replyList,setReplyList] = useState([]);
   const [ replyTotal,setReplyTotal] = useState(0);
   const [ quizDetail, setQuizDetail ] = useState([]);
   const [ isReply, setIsReply ] = useState([]);
   let num = new URLSearchParams(props.location.search).get('num');
   useEffect(() => {
      fetchData('getMessagesList', { num, start: 0, limit: 15,}).then((result) => {
         setReplyList(result.data.list);
         setReplyTotal(result.data.total);
      })
   },[num]);
   useEffect(() => {
      fetchData('getNewMessagesNumber').then((result) => {
         if (result) {
             dispatch({
                 type: 'CHANGE_SHOW_NEWS',
                 isShowNews: result.data.total,
             })
         }
      }).catch((err) => {
            
      });
   }, [dispatch]);
   useEffect(() => {
      fetchData('getQuestionDetail',{ num, }).then((res) => {
          if (res && res.data.type !== 'im') {
             let d = res.data;
             setIsReply(d.is_reply)
               // let info = d.info.map((item,index) => {
               //       return {
               //          label: item.label,
               //          value: item.value,
               //          key: String(index),
               //       }
               // })
               
              d.info && d.info.forEach((item, index) => {
                 item.key = String(index);
                 if (/\*/.test(item.label)) {
                    item.label = item.label.replace('*','');
                 }
              })
              setQuizDetail(d);
              if (d.complaint_fkey) {
                  dispatch({
                     type: 'ADD_COMPLAINTFKEY',
                     fkey: d.complaint_fkey,
                  })
              }
              
            //   setTitle(d.)
          } else {
            setQuizDetail(res.data);
          }
      })
  },[num,dispatch])
   const textChange = (e) => {
      setInpVal(e.target.value)
   }
   const pageChange = (page) => {
      fetchData('getMessagesList', { num, start: (page-1)*15, limit: 15,}).then((result) => {
         setReplyList(result.data.list);
      })
   }
   const submitQuestion = () => {
      if (!inpVal) {
         message.warn('回复内容不可为空');
         return;
      }
      setLoading(true);
      const options = {
         num,
         record: inpVal,
      }
      if (fileList.length > 0 ) {
         options.attachs = fileList.map(item => {
            return {
               url: item.response.data.url,
               type: item.response.data.type
            }
         })
      }
      fetchData('submitQuestion',options).then((result) => {
         if (result) {
            fetchData('getMessagesList', { num, start: 0, limit: 16,}).then((result) => {
               setReplyList(result.data.list);
               setFileList([]);
               setInpVal('');
               message.success('提交成功');
               setIsReply('0');
               setLoading(false);
            })
         }
      })
   }
   const setOptions = () => {
        let options = {};
        const params = new URLSearchParams(window.location.search);
        qucParams.forEach(item => {
            let param = params.get(item);
            if (param) {
                options[item] = param;
            }
        });
        return options;
    }
   const params = {
      name: 'file',
      action: '//urm.help.360.cn/cg/form/uploadAttachments',
      headers: {
        authorization: 'authorization-text',
      },
      withCredentials: true,
      onChange(info) {
        const status = info.file.status;
        if (status !== 'uploading') {
         //   setFileList([...info.fileList])
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
        setFileList([...info.fileList])
      },
      data: setOptions()
   };
   return ( 
     <section style={style}>
        <style>
           {`
               .ant-textarea {
                  font-size: 12px;
                  borderColor: #d9d9d9;
                  border-radius: 0;
               }
               .ant-textarea:focus,.ant-textarea:hover {
                  border-color: #d9d9d9;
                  box-shadow: none;
               }
               .upload-attachment>span {
                  display: flex;
               }
               .upload-attachment .ant-upload-list-item-name {
                  font-size: 12px;
                  // padding-left: 8px;
               }
               .upload-attachment .ant-upload-list-item-info>span {
                  display: flex;
                  align-items: center;
               }
               .upload-attachment .anticon-paper-clip {
                  position: static;
                  top: none;
               }
               .upload-attachment .anticon-close>svg {
                  display: none;
               }
               .upload .ant-btn-primary {
                  background: #D0021B;
                  border-color: #D0021B;
                  padding: 0 34px;
               }
               .ant-empty-image img {
                  margin: 0 auto;
               }
               .ant-pagination-item-active {
                  border-color: #d0021b;
                }
               .ant-pagination-item-active a {
                  color: #d0021b;
                }
           `}
        </style>

        <style jsx>{`
            .table {
               border: 1px solid #d9d9d9;
            }
            .tr-title {
               display: flex;
               background: #f4f4f4;
               min-height: 37px;
               line-height: 37px;
               padding-left: 12px;
               border-bottom: 1px solid #d9d9d9;
               font-weight: bold;
            }
            .type {
               width: 187px;
            }
            .num {
               width: 199px;
            }
            .title {
               flex: 1
            }
            .tr-content {
               display: flex;
               // height: 44px;
               // line-height: 34px;
               text-align: justify;
               padding-left: 12px;
               // border-bottom: 1px solid #d9d9d9;
            }
            .tr-content .type,.tr-content .num, .tr-content .title {
               padding: 11px;
               padding-left: 0;
            }
            .tr-record {
               background: #f4f4f4;
               height: 37px;
               line-height: 37px;
               padding-left: 12px;
               font-weight: bold;
               display: ${replyList.length>0 ? 'block' : 'none'};
            }
            .reply-edit {
               padding: 18px;
               background: #fff;
               margin-top: 10px;
               display: ${isReply === '1' ? 'block' : 'none'};
            }
            .text-area {
               margin-top: 8px;
               margin-bottom: 8px;
            }
            .upload {
               margin-top: 8px;
               display: flex;
               justify-content: space-between;
            }
            .upload-attachment {
               display: flex;
               align-items: flex-end;
            }
            .attachment-button {
               width: 74px;
               height: 27px;
               color: #999;
               border: 1px solid #999;
               box-sizing: border-box;
               font-size: 12px;
            }
            .attachment-name {
               // line-height: 1;
               margin-left: 6px;
            }
            .delete {
               // line-height: 1;
               margin-left: 6px;
               color: #999;
               display: ${fileList.length > 0 ? 'block' : 'none'};
            }
            .upload-submit {
               width: 103px;
               height: 27px;
               background: red;
               color: white;
            }
        `}
        </style>
        <section className="table">
            <div className="tr-title">
               <p className="type">问题编号</p>
               <p className="num">问题类型</p>
               <p className="title">问题标题</p>
               <p className="num">游戏名称</p>
               <p className="title">评价</p>
            </div>
            <div className="tr-content">
               <p className="type">{quizDetail.num}</p>
               <p className="num">{quizDetail.category}</p>
               <p className="title">{quizDetail.title}</p>
               <p className="num">{quizDetail.platform ? `${quizDetail.platform}${quizDetail.game}` : ''}</p>
               <p className="title">{quizDetail.assess_info ? quizDetail.assess_info.level + quizDetail.assess_info.improve : ''}</p>
            </div>
            { quizDetail.type !== 'im' && (<div>
               <QuizInfo num={num} setIsReply={setIsReply} quizDetail={quizDetail.info} title={quizDetail.title} />
            </div>) }
            <div className="tr-record">
               <p>问题记录</p>
            </div>
            <ul>
               {replyList.map((item,i) => {
                  return (<li key={i}><Reply index={i} {...item} length={replyList.length} /></li>)
               })}
            </ul>

        </section>
        {   replyTotal>15 && 
            <Pagination 
               pageSize={15} 
               total={replyTotal} 
               onChange={pageChange} 
               style={{float: 'right', margin: '20px 0 0'}} 
            />
         }

        <section className="reply-edit">
            <p className="tip">
               回复客服：
            </p>
            <div className="text-area">
               <TextArea className="ant-textarea" rows={3} value={inpVal} onChange={textChange} />
            </div>
            <section className="upload">
               <div className="upload-attachment">
                  <Upload {...params} fileList={fileList}>
                     <button className="attachment-button">上传附件</button>
                  </Upload>
                  <button className="delete" onClick={() => setFileList([])}>删除</button>
               </div>
               <Button type="primary" onClick={submitQuestion} loading={loading}>提交</Button>
            </section>
        </section>
     </section>
   )
}
function mapStateToProps(state) {
   return {
      complaintfkey: state.questionReducer.complaintfkey,
      // isShowNews: state.showReducer.isShowNews,
   }
}
export default connect(mapStateToProps)(QuizDetail)