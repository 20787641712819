import React from 'react';
import { withRouter } from 'react-router';
import imgUrl from 'assets/images/serviceAvatar.png';
import imgUrl2 from 'assets/images/consultFont.png';
import { connect } from 'react-redux';

const toOnlineConsultation = (history) => {
    // history.push('/onlineconsultationim')
    window.open('https://profile.wg.360.cn/api/sso/loginIM?display=wot')
}

const CustomerService = (props) => {
    const { location } = props.history;
    let pathname = location.pathname;
    let search = location.search;
    const style = {
        position: 'fixed',
        cursor: 'pointer',
        right: '20px',
        top: '320px',
        zIndex: '9999',
        // display: pathname === '/onlineconsultation' && search.includes('fkey') ? 'none' : 'block',
    }
    return (
      <section style={style} onClick={() => toOnlineConsultation(props.history)}>
         <style jsx>{`
             .top {
                height: 38px;
                font-size: 14px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                background: linear-gradient(#DF343E, #B81C25);
             }
             .top h6 {
                font-size: 14px;
             }
             .title {
                 color: white;
             }
             .avatar {
                //  padding: 5px 14px;
                width: 80px;
                height: 80px;
             }
             .bottom {
                 height: 28px;
                 width: 78px;
             }
             .avatar img, .bottom img {
                width: 100%;
                height: 100%;
            }
         `}
         </style>
         {/* <div className="top">
            <h6 className="title">在线客服</h6>
         </div> */}
         <div className="avatar">
             <img src={imgUrl} alt=''/>
         </div>
         <div className="bottom add-pointer">
             <img src={imgUrl2} alt=''/>
         </div>
      </section>
    )
}

function mapStateToProps(state) {
    return {
        isShowOnlineService: state.showReducer.isShowOnlineService
    };
}
export default connect(mapStateToProps)(withRouter(CustomerService));