import React, {useEffect, useState} from 'react';
// import { Collapse, Icon,} from 'antd';
import BaseIcon from 'assets/svgIcon/baseIcon';
import hotQuizData from './hotQuizData';
import {fetchData} from 'helpers';

// const {Panel} = Collapse;

const LiSingle = (props) => {
  const {title, content, i, showNum, fn} = props;
  return (
    <section>
      <style jsx>
        {`
          .question {
            display: flex;
            align-items: center;
            // font-size: 12px;
            margin-bottom: 8px;
          }
          .question-content {
            background: #f8f8f8;
            // height:206px;
            // width: 100%;
          }
          .title {
            margin-left: 8px;
          }
          .question-content {
            // font-size: 12px;
            padding: 15px 20px;
            display: ${i === showNum ? 'block' : 'none'};
            // transition: opacity 2s;
          }
          .question-content :global(img){
            max-width:100%;
          }
        `}
      </style>
      <div className="question add-pointer" onClick={() => fn(i)}>
        <BaseIcon iconName="QuestionCircle" width={16} height={16}/>
        <p className="title">{title}</p>
      </div>
      <div className="question-content" dangerouslySetInnerHTML={{__html:content}} style={{whiteSpace: 'pre-wrap'}}>
      </div>
    </section>
  )
}

const HotQuiz = (props) => {
  const quizIndex = new URLSearchParams(props.location.search).get('quizindex');
  const [showNum, setShowNum] = useState(Number(quizIndex));
  const [noticeList, setNoticeList] = useState([]);
  useEffect(() => {
    fetchData('getFaqList', { limit: 0,gkey:'wg'}).then((res) => {
      setNoticeList(res.data);
    }).catch((err) => {

    });
  }, [])

  const itemClick = (index)=>{
    let item = noticeList[index];
    item && fetchData('postFaqStatistics', {id: item.id});
    setShowNum(index);
  }

  const style = {
    width: '100%',
    // height: '600px',
    padding: '15px 18px 30px',
    boxSizing: 'border-box',
    background: 'white',
    border: '1px solid #d9d9d9'
  }
  // const customPanelStyle = {
  //   background: '#f7f7f7',
  //   borderRadius: 4,
  //   marginBottom: 24,
  //   border: 0,
  //   overflow: 'hidden',
  //   whiteSpace: 'pre-wrap'
  // };
  return (
    <section style={style}>
      {/* <style>
           {`
            .center-container .ant-collapse-header {
              display: flex !important;
              padding-left: 16px !important;
              line-height: 1.2 !important;
              background: white;
            }
           `}
         </style> */}
      <style jsx>{`
             .quiz {
               margin-top: 15px;
             }
         `}
      </style>
      {/* <Collapse
            bordered={false}
            defaultActiveKey={['0']}
            expandIcon={() => <BaseIcon iconName="QuestionCircle" width={15} height={15} />}
         >
           {
             hotQuizData.map((item,index) => {
               return (
                <Panel header={item.question} key={index} style={customPanelStyle}>
                  <p>{item.answer}</p>
                </Panel>
               )
             })
           }
         </Collapse> */}
      <ul className="content">
        {noticeList.map((item, index) => {
          return (
            <li className="quiz" key={index}><LiSingle {...item} i={index} showNum={showNum} fn={itemClick}/></li>)
        })}
      </ul>
    </section>
  )
}

export default HotQuiz