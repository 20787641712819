import "whatwg-fetch"
import { message } from 'antd';
import store from '../store';
import quc from '../login/quc';

const apiList = [
    // 获取表单信息
    ['getFormDetail', 'POST', 'cg/form/ajaxTempDetail', 'json'],
    // 提交表单
    ['submitForm', 'POST', 'cg/form/ajaxTempContentsSubmit', 'json'],
    // 上传文件
    ['uploadAttachments', 'POST', 'cg/form/uploadAttachments', 'file'],
    //热门问题列表
    ['getFaqList', 'GET', 'cg/customer/faqCateList', 'json'],
    //热门问题点击量统计
    ['postFaqStatistics', 'POST', 'cg/customer/faqStatistics', 'json'],
    // 公告列表
    ['getNoticeList', 'GET', 'cg/customer/noticeList', 'json'],
    // 公告详情
    ['getNoticeDetail', 'GET', 'cg/customer/noticeDetail', 'json'],
    // banner列表
    ['getBannerList', 'GET', 'cg/customer/bannerList', 'json'],
    // 我的问题列表
    ['getFormQuestionList', 'POST', 'cg/form/ajaxQuestionList', 'json'],
    // 问题大类列表
    ['getQuestionCateList', 'GET', 'cg/customer/questionCate', 'json'],
    // 问题列表
    ['getQuestionList', 'GET', 'cg/customer/questionList', 'json'],
    // 问题消息列表
    ['getMessagesList', 'POST', 'cg/form/ajaxQuestionMessages', 'json'],
    // 我的问题详情
    ['getQuestionDetail', 'POST', 'cg/form/ajaxQuestionDetail', 'json'],
    // 无fkey时的我的问题详情（静态富文本）
    ['getQuestionStaticDetail', 'GET', 'cg/customer/questionDetail', 'json'],
    // 问题消息提交
    ['submitQuestion', 'POST', 'cg/form/ajaxQuestionMessageSubmit', 'json'],
    // 获取新消息
    ['getNewMessagesNumber', 'POST', 'cg/form/ajaxQuestionNewMessagesNumber', 'json'],
    // 我的问题评价
    ['submitEvaluate', 'POST', 'cg/form/ajaxEvaluateQuestion', 'json'],
    // 平台相关游戏信息搜索
    ['ajaxGameSearch', 'POST', 'cg/Form/ajaxGameSearch', 'json'],
    // 获取近期玩过的页游信息列表
    ['ajaxLatelyGames', 'POST', 'cg/form/ajaxLatelyGames', 'json'],
    // 游戏相关区服信息搜索
    ['ajaxZoneSearch', 'POST', 'cg/Form/ajaxZoneSearch', 'json'],
    // 分配用户客服信息，唤起游戏IM工具
    ['getIMTool', 'POST', 'game/game/consult', 'json'],
    // 游戏等级、IM是否可用
    ['checkImUsable', 'POST', 'cg/customer/imUsable', 'json'],
]

let apiListMap = apiList.reduce((sum, prev) => {
    if(!prev || !prev[0] || !prev[1]) return sum;
    sum[prev[0]] = prev;
    return sum
}, {})

function getContentType(contentType) {
    if(contentType === 'json') return 'application/json';
    if(contentType === 'form') return 'application/x-www-form-urlencoded';
    if(contentType === 'file') return 'multipart/form-data';
}
let qucParams = ['qid', 'timestamp', 'src', 'ukey', 'fkey'];

const setOptions = () => {
    let options = {};
    const params = new URLSearchParams(window.location.search);
    qucParams.forEach(item => {
        let param = params.get(item);
        if (param) {
            options[item] = param;
        }
    });
    return options;
}

const fetchData = function(url, data, timeout) {
    return Promise.race([
        fetch(url, data),
        new Promise((resolve, reject) => {
            setTimeout(() => reject(new Error('Timeout')), timeout);
        })
    ])
}

function finalFetch(url, init, timeout) {
    return new Promise((resolve, reject) => {
        var host = '//urm.help.360.cn/';
        fetchData(host+url, init, timeout).then(res => {
                if(res.status === 200) {
                    return res.json();
                } else {
                    message.error('未知错误');
                }
            }).then(data => {
                if (!data.errno) {
                    resolve(data)
                } else if(data.errno === 2) {
                    store.dispatch({
                        type: 'CHANGE_QUC_LOGIN',
                        qucLoginStatus: true,
                    })
                    quc.then(() => {
                        window.QHPass.signIn(function(userInfo) {
                            store.dispatch({
                                type: 'SET_LOGIN_STATUS',
                                isLogin: userInfo.nickname ? userInfo.nickname : userInfo.username,
                            })
                            message.success('登录成功');
                            // const res = await finalFetch()
                            // 登录成功后再次请求相同的接口返回出去
                            window.location.reload();
                            resolve(finalFetch(url, init, timeout))
                        });
                    })
                    return;
                } else {
                    message.error(data.errmsg);
                    resolve('');
                    // reject('error')
                }
            })
            .catch(error => {
                message.error('未知错误2');
            })
    })
}

export default function(name, data, timeout = 10000) {
    let url = apiListMap[name][2];
    let init = {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'include',
        mode: 'cors',        //包含Cookie
    }
    let options = { ...data, ...setOptions()};
    if (apiListMap[name][1] === 'POST') {
        init.method = 'POST';
        if (apiListMap[name][3] === 'file') {
            init.body = options;
        } else {
            init.headers = {
                'Content-Type': getContentType(apiListMap[name][3]),
            }
            init.body = JSON.stringify(options);
        }
    }

   if(init.method === 'GET' && options && Object.keys(options).length > 0) {
       let searchStr = '';
       let strArr = [];
       if(options instanceof Object) {
           for(let i in options) {
               strArr.push( i + '=' + options[i] )
           }
       }
       url = url + '?' + strArr.join('&');
   }
   return finalFetch(url,init,timeout)
}