const data = [
    {
        question: '账户中已有永久坦克/战舰，能否再次获得同款永久坦克/战舰或时限坦克/战舰',
        answer: `非常抱歉，已有永久坦克/战舰是无法重复获得的。`
    },
    {
        question: '如何通过密保信息来找回密码',
        answer: `您可以复制此链接http://i.360.cn/findpwd/?account=到浏览器，进行找回密码操作`
    },
    {
        question: '历史充值扣款记录查询',
        answer: `历史充值扣款记录查询如下：
【电脑端支付宝查询方式】：请登录支付宝——交易记录——找到您对应月份游戏相应的充值记录
【手机支付宝查看方式】：打开手机支付宝，【我的】——【账单】查看您对应月份的充值记录
【微信充值查询方式】：请登录微信——【我】——【支付】——【钱包】——钱包右上角【账单】——查询交易记录找到指定日期充值记录——点击此笔充值记录后截图
【网银充值查询方法】：请您复制链接：http://urlqh.cn/kawy8，到浏览器查看对应银行查询方式。（此链接仅包含部分银行的查询功能，其他银行查询请登录银行官网咨询客服或自助查询）`,
    },
    {
        question: '支付宝/微信第三方扣款截图删除如何再次获取',
        answer: `【支付宝删记录查询方法】
1.如果您的支付宝记录已经删除，请您使用电脑登录支付宝-点击交易记录-右上角回收站-找到对应的支出详情-点击详情后截图（截图需包含完整的交易号、时间、金额）然后将截图添加附件发送给我们以便我们核实处理。
2.如果您的支付宝支出记录回收站被清空，您可以联系支付宝在线客服（支付宝-我的客服-交易记录-如何查看交易记录-向我提问）告知客服查询已删除回收站的支出记录查询，告知客服具体交易时间，然后索要19位数的订单号，提供给我们。
【微信删除记录查询方法】
微信充值记录已删的情况下，您可以联系微信客服申请找回已删除的充值记录。（打开微信搜索【微信支付】公众号，点击【其他帮助】—【更多问题】—【联系客服】打开微信【我】—点击【支付】—【钱包】—点击【帮助中心】—【联系客服】）以上联系微信客服找回充值记录方式仅供参考。`,
    },
    {
        question: '手机换号，无法自助修改绑定手机怎么办',
        answer: `您可以登录客服中心（https://kf.wg.360.cn/），在主页的最下方点击【账号问题】栏中的【清空密保手机】按钮，填写表单相关信息并点击提交表单，工作人员会尽快为您处理，请您耐心等待。`,
    },
    {
        question: '怎样冻结/解冻账号',
        answer: `1. 账号下有绑定且激活的密保手机，并与安全卫士进行关联时：
账号卫士APP登录自己的360账号，— 点击【紧急冻结】— 点击【冻结账号】即可冻结成功解冻账号点击【解冻账号】即可解冻
2. 账号下绑定密保手机时，可发短信冻结/解冻账号：
①冻结：使用绑定手机号发送冻结指令 SDZH 至 10690133605 冻结帐号，冻结后的帐号无法登录  
②解冻：您可以使用绑定手机号发送解冻指令 JSZH 至 10690133605 解除冻结`,
    },
    {
        question: '游戏内充值未到账怎么办？',
        answer: `由于网络波动等原因，充值可能会有15-30min的充值延迟，请您耐心等待。
如果充值超过30分钟请您官方客服提供订单号、商户单号等相关信息。`,
    },
//     {
//         question: '页游无法登录常规操作',
//         answer: `一般会有两种可能：
// 一、服务器维护/开合服等官方操作行为
// 如果是第一种的情况下，还请您耐心等待维护结束。
// 二、故障导致的错误提示【排故障操作】如下：
// 建议您可以先进行一下全面、彻底的清理：
// 1、换一个登录方式,清理缓存，优化网络,优化电脑上的DNS,更新浏览器尝试一下。（可通过360安全卫士中的DNS修复功能进行操作）
// 2、如果您还是进不去游戏，您可以尝试切换浏览器极速模式、兼容模式或下载360极速浏览器进行尝试的。
// 3、如果是微端或者游戏大厅进入，建议您卸载重新安装一下
// 【注：如果上述方式无效，建议您打开客服中心（https://kf.wg.360.cn/），点击在主页的最下方【游戏问题】栏中的【无法登录】按钮（如没有显示请点击栏中的【更多】按钮），并填写您的游戏信息后点击发起咨询，填写表单并点击提交表单，工作人员会尽快为您处理请您耐心等待。】`,
//     },
//     {
//         question: '手机游戏如何切换账号',
//         answer: `已登录状态您可以尝试以下方法：
// 1、可以点击游戏里360手机助手的悬浮窗-个人-切换账号。
// 2、部分游戏在加载登录时界面上方一般有一个白色的“切换账号”提示，您点击后可以尝试切换账号。
// 未登录状态您可以尝试以下方法：
// 登录游戏时账号登录界面左下方会提示切换登录方式（账号密码登录及手机号短信验证登录），您可以选择您常用的登录方式进行操作。`,
//     },
//     {
//         question: '积分/云钻竞拍规则',
//         answer: `竞拍规则如下：
// 1.竞拍结束后根据商品数量来确定竞拍成功的用户，例如商品数量为10，前10名出价的用户可中标
// 2.出价相同的用户，先出价成功的用户排名靠前
// 3.出价成功后积分将会冻结
// 4.竞拍中,出价用户被淘汰,可取消竞拍，取消后积分返回该用户360积分商城账户
// 5.竞拍结束后，未竞拍成功的用户积分会返回该用户360积分商城账户
// 6.竞拍结束后,竞拍成功的用户积分将扣除,无论是否领取奖品,积分均不退回。
// 7.如果是需要支付金额的商品,竟拍成功后无论是否支付购买,所有消耗的积分不退回
// 8.中标后请在5天内支付,否则视为放弃购买资格。
// 9.积分相关竞拍：缴纳的门票费不会退回。`,
//     },
//     {
//         question: '二级密码修改',
//         answer: `二级密码是游戏中的财产保护密码，包括仓库密码、背包密码等。
// 需要您回到首页，在客服中心首页最下方【游戏问题】栏中，选择【二级密码】（如没有显示请点击栏中的【更多】）的表单并点击提交，我们接收到后，会第一时间为您处理
// 以下特殊游戏处理方案：
// 1.《问道》需要您在游戏内点击“设置”-“管理”-“安全锁”，申请强制解锁，耐心等待7天，自动清除。
// 2.《大话西游》需要您在游戏内点击“系统”申请强制解锁，耐心等待5天，自动清除。
// 3.《梦幻西游》需要玩家您提供充值常用设备MAC码（充值最多的设备）+角色ID，填写首页最下方【游戏问题】栏中，选择【二级密码】（如没有显示请点击栏中的【更多】）的表单并点击提交；或者您在游戏内点击“系统”申请强制解锁，耐心等待10天，自动清除。
// 4.端游《桃花源记2》在首页最下方【游戏问题】栏中，选择【二级密码】（如没有显示请点击栏中的【更多】）的表单并点击提交；另需要您提供游戏内认证的身份证反正面照片`,
//     },
//     {
//         question: '修改账号昵称',
//         answer: `昵称不限制修改次数
// 设置方法如下：
// 1. 电脑登录 i.360.cn  登录自己的360账号后 — 点击头像右侧【设置昵称】进行设置（昵称限制2-14个字符，支持中英文）
// 2. 手机登录 i.360.cn  登录自己的360账号后 — 点击【个人资料】—点击【昵称】进行设置—设置完毕后点击【保存】即可（安卓、苹果流程一致）"`,
//     },
//     {
//         question: '如何注销账号',
//         answer: `浏览器登录i.360.cn，登录360账号：
// ①点击【账号安全】—点击【账号服务】—点击【继续注销账号】
// ②选择验证方式—输入收到的验证码—账号注销确认（等待时间约20秒左右）
// ③点击【继续注销】—勾选“已阅读并同意《360账号注销协议》”—点击【同意并继续】
// ④勾选离开原因—填写联系邮箱，输入邮箱收到的验证码—页面提示申请提交成功
// ⑤结果会在4个工作日内发送到用户的联系邮箱（如果用户账号下未绑定密保信息，则点击【继续注销账号】后需要验证账号登录密码）`,
//     },
//     {
//         question: '如何设置实名认证',
//         answer: `浏览器登录wan.360.cn：登录自己的360账号后，点击【个人中心】—账号安全—防沉迷设置【立即设置】—填写姓名、身份证号后点击【确定】即可保存成功；
// 游戏内：登录自己的360账号后，游戏页面会弹窗提示填写实名认证，填写姓名、身份证号后点击【确定】即可保存成功；`,
//     },
//     {
//         question: '怎样冻结/解冻账号',
//         answer: `1. 账号下有绑定且激活的密保手机，并与安全卫士进行关联时：
// 账号卫士APP登录自己的360账号，— 点击【紧急冻结】— 点击【冻结账号】即可冻结成功；解冻账号点击【解冻账号】即可解冻；
// 2. 账号下绑定密保手机时，可发短信冻结/解冻账号：
// ①冻结：使用绑定手机号发送冻结指令 SDZH 至 10690133605 冻结帐号，冻结后的帐号无法登录  
// ②解冻：您可以使用绑定手机号发送解冻指令 JSZH 至 10690133605 解除冻结`,
//     },
//     {
//         question: '360币支付密码设置',
//         answer: `pay.360.cn：浏览器登录pay.360.cn，登录自己的360账号后，页面会弹窗提示，输入登录密码— 设置支付密码— 确认支付密码 — 设置安全问题 — 输入安全问题答案— 输入账号下绑定的密保手机收到的验证码— 点击【确定】即可设置成功
        
// 注：如账号下没有绑定密保手机则无法设置360币支付密码及安全问题）`,
//     },
//     {
//         question: '如何修改360币支付的安全问题',
//         answer: `您可以点击进入pay.360.cn，登录自己的360账号后，点击【我的账户】--【安全设置】—在安全设置中找到【安全问题】右侧点击【修改】即可进行安全问题的修改。`,
//     },
//     {
//         question: '360币重置支付密码',
//         answer: `以下情况仅适用于安全问题知道答案的情况下用户自行操作
// pay.360.cn：浏览器登录pay.360.cn，登录自己的360账号后，点击【我的账户】— 点击【修改支付密码】— 点击【忘记密码】— 输入设置的安全问题答案— 设置新密码— 确认新密码— 点击【确定】即可保存成功；

// 注：如果用户将安全问题答案一并忘记，需联系客服处理，手游联系邮箱为：m-kefu@360.cn   页游邮箱为：payhelp@360.cn`,
//     },
//     {
//         question: '换绑360密保手机号',
//         answer: `1. 浏览器登录i.360.cn进行设置：
// 官网PC版：
// ①登录360账号后—点击左侧常用操作中绑定手机【修改】——进行设置
// ②登录360账号后—点击账号安全—密保工具—绑定手机【修改】——进行设置
// ③登录360账号后—点击账号安全—点击左侧绑定手机——【修改】——进行设置；
// 官网手机版：
// 登录360账号后—点击【我的密保】—绑定手机【修改】——进行设置（安卓、苹果流程一致）
// 2. 浏览器登录wan.360.cn进行设置
// 登录360账号后—点击【个人中心】—点击【账号安全】—绑定手机【修改】——进行设置（安卓、苹果与网页版流程一致）
// 3. 手游游戏内：
// 登录360账号—点击悬浮球—点击【安全中心】—点击【密保工具】—绑定手机【修改】——进行设置
// 特殊说明：
// ①账号已绑定登录邮箱/密保邮箱（密保信息可用且激活状态）情况下自行操作修改密保手机
// ②密保信息不可用情况下或手机换号无法自助修改绑定手机时需填写表单提交账号申诉，由工作人员核实信息（如账号是360U开头且账号密保信息只绑定了手机号，无法直接删除账号密保手机，需要修改完账号用户名后才可以进行操作）`,
//     },
//     {
//         question: '换绑360密保邮箱',
//         answer: `1. 浏览器登录i.360.cn进行更改：
// 登录360账号后—点击账号安全—密保工具—密保邮箱【修改】—进行更换（激活完成后即绑定成功）
// 2. 浏览器登录wan.360.cn进行更改：
// 登录360账号后—点击【个人中心】—点击【账号安全】—密保邮箱【修改】—进行更换（激活完成后即绑定成功）
// 特殊说明：
// 账号如有绑定其他密保信息（密保信息可用且激活状态），在更换密保邮箱时，需先验证其他密保信息`,
//     },
//     {
//         question: '换绑360登录邮箱',
//         answer: `1. 浏览器登录i.360.cn进行更改：
// 官网PC版：
// ①登录360账号后—点击左侧常用操作中登录邮箱【修改】—进行更换（激活完成后即绑定成功）
// ②登录360账号后—点击账号安全—密保工具—登录邮箱【修改】—进行更换（激活完成后即绑定成功）
// 官网手机版：
// 登录360账号后—点击【我的密保】—登录邮箱【修改】—进行更换（激活完成后即绑定成功）（安卓、苹果流程一致）
// 2.手游游戏内：进入游戏后登录自己的360账号—点击悬浮球—点击【安全中心】—点击【密保工具】—登录邮箱【修改】—进行更换（激活完成后即绑定成功）
// 特殊说明：
// 账号如有绑定其他密保信息（密保信息可用且激活状态），在修改登录邮箱时，需先验证其他密保信息`,
//     },
]

export default data;