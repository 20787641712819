import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import QuickBoot from './quickBoot';
import Recharge from './recharge';
import quizData from './quizData';

const types = [
  // {type:'login',name:'登录问题'},
  {type:'game',name:'游戏问题'},
  {type:'recharge',name:'充值问题'},
  // {type:'platform',name:'平台问题'},
  {type:'account',name:'账号问题'},
]


// 自助服务
const Faq = (props) => {
    const { TabPane } = Tabs;
    const { activeKey } = props;
    const changeActiveKey = (activeKey) => {
      props.dispatch({
        type: 'CHANGE_QUESTION_TYPE',
        activeKey,
      })
    }
    return (
      <section>
         <style>{`
            .card-container .ant-tabs-content {
              background: #fff;
              // padding: 10px 26px 10px;
              border: 1px solid #d9d9d9;
              border-top: none;
            }
             .card-container > .ant-tabs-card > .ant-tabs-content {
              margin-top: -16px;
            }
            
            .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
              background: #fff;
              padding: 35px;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              // border: 1px solid #d9d9d9;
            }
            
            .card-container > .ant-tabs-card > .ant-tabs-bar {
              border-color: #d9d9d9;
            }
            
            .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
              border-color: #d9d9d9;
              background: #f2f2f2;
              padding: 0 28px;
              margin-right: 0;
              font-size: 16px;
              // transform: skew(15deg);
            }
            
            .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
              border-color: #d9d9d9;
              border-bottom-color: #fff;
              background: #fff;
            }
            .card-container .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
              color: #d0021b;
            }
            .card-container .ant-tabs-nav .ant-tabs-tab:hover {
              color: #d0021b;
            }
         `}
         </style>
         <div className="card-container">
          <Tabs type="card" activeKey={activeKey} onChange={changeActiveKey}>
            <TabPane tab="快速引导" key="1">
              {
                types.map((item,index) => {
                  return <QuickBoot content={quizData[item.type].slice(0,5)} title={item.name} key={index} activeKey={String(index+2)} quizType={item.type} />
                })
              }
            </TabPane>
            {
              types.map((item,index) => {
                return (
                    <TabPane tab={item.name} key={String(index+2)}>
                      <Recharge questions={quizData[item.type]} type={item.type} />
                    </TabPane>
                )
              })
            }
            {/* <TabPane tab="登录问题" key="2">
              <Recharge questions={quizData.login} />
            </TabPane>
            <TabPane tab="游戏问题" key="3">
              <Recharge questions={quizData.game} />
            </TabPane>
            <TabPane tab="充值问题" key="4">
              <Recharge questions={quizData.recharge} />
            </TabPane>
            <TabPane tab="平台问题" key="5">
              <Recharge questions={quizData.platform} />
            </TabPane>
            <TabPane tab="账号问题" key="6">
              <Recharge questions={quizData.account} />
            </TabPane> */}
          </Tabs>
        </div>
      </section>
    )
}
                          
function mapStateToProps(state) {
  return {
      activeKey: state.questionReducer.activeKey,
  }
}

export default connect(mapStateToProps)(Faq)