import React, {useEffect, useState} from 'react';
import BaseIcon from 'assets/svgIcon/baseIcon';
import {withRouter} from 'react-router';
import hotQuizData from '../detailServices/hotQuiz/hotQuizData';
import {Icon} from 'antd';
import {fetchData} from 'helpers';

const reportList = hotQuizData.map(item => item.question).slice(0, 5);

const Report = (props) => {
  const {content} = props
  const style = {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    position: 'relative',
    // paddingLeft: '10px',
    marginTop: '12px',
  }
  return (
    <section style={style}>
      <style jsx>{`
             
             .circle {
                 display: block;
                 width: 4px;
                 height: 4px;
                 border-radius: 50%;
                 background: #d9d9d9;
                //  flex: 1;
                //  position: absolute;
                //  top: 50%;
                //  left: 0;
                //  transform: translateY(-50%);
             }
             .report-content {
                width: 300px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-left: 8px;
                line-height: 1.4;
                flex: 1;
             }
             .report-date {
                 font-size: 12px;
             }
         `}
      </style>
      <em className="circle"></em>
      <p className="report-content add-pointer">
        {content}
      </p>
      {/* <p className="report-date">
             2019-6-20
         </p> */}
    </section>
  )
}

const toRoute = (history, index, item) => {
  // console.log(item);

  item && fetchData('postFaqStatistics', {id: item.id});
  history.push(`/hotquiz?quizindex=${index}`)
}

// 公告板块
const Announcement = (props) => {
  // const reportList = ['啦啦啦','啦啦啦','啦啦啦','啦啦啦','啦啦啦','啦啦啦','啦啦啦','啦啦啦','啦啦啦','啦啦啦','啦啦啦',]
  const [noticeList, setNoticeList] = useState([]);
  useEffect(() => {
    fetchData('getFaqList', { limit: 5,gkey:'wg'}).then((res) => {
      setNoticeList(res.data);
    }).catch((err) => {

    });
  }, [])
  return (
    <section>
      <style jsx>{`
             .announcement {
                width: 472px;
                height: 208px;
                background: white;
                box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.14);
                border-top-right-radius: 10px;
                overflow: hidden;
             }
             .title-fence {
                height: 40px;
                background: linear-gradient(#fff, #f0f0f0);
                display: flex;
                align-items: center;
                padding-left: 10px;
                position: relative;
                overflow: hidden;
                font-size: 14px;
             }
             .report-list {
                 height: 168px;
                 padding: 0 10px 10px;
                 box-sizing: border-box;
             }
             .more {
                width: 84px;
                height:40px;
                background: #eee;
                box-shadow: 1px 1px 2px 0px #aaa inset;
                color: #999;
                line-height: 40px;
                text-align: center;
                position: absolute;
                right: -10px;
                top: 0;
                transform: skew(20deg);
              }
              .more .more-text {
                display: inline-block;
                transform: skew(-20deg);
                // font-size: 12px;
              }
         `}
      </style>
      <section className="announcement">
        <div className="title-fence">
          <BaseIcon iconName="HotQuiz" width={16} height={16}/>
          <span style={{fontWeight: 'bold', marginLeft: '6px', fontSize: '16px'}}>热门问题</span>
          <div className="more add-pointer" onClick={() => toRoute(props.history, 0)}>
            <span className="more-text">更多 <Icon type="right"/></span>
          </div>
        </div>
        <ul className="report-list">
          {noticeList.map((item, index) => {
            return (
              <li key={index} onClick={() => toRoute(props.history, index, item)}><Report content={item.title}/></li>)
          })}
        </ul>
      </section>
    </section>
  )
}

export default withRouter(Announcement)